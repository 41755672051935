<template>
    <div class="w-full">
        <ConfirmationModal codeConfirmation @success="getAll" />

        <ModalEditHistory
            v-if="modalEditHistoryVisible"
            :modalEditHistory="modalEditHistory"
            :selectedHistoryProp="selectedHistory"
            @refresh="getAll"
            @close="modalEditHistory = false"
            @closed="modalEditHistoryVisible = false"
        />

        <ModalAddHistory
            v-if="modalAddHistoryVisible"
            :modalAddHistory="modalAddHistory"
            @refresh="getAll"
            @close="modalAddHistory = false"
            @closed="modalAddHistoryVisible = false"
        />

        <Summary :historyData="historyData" />

        <div class="card main-card flex flex-col">
            <div class="flex justify-between">
                <div class="select">
                    <ClientsDropdown
                        withArchive
                        @selected="getAll"
                    />
                </div>
                <div class="menu flex">
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('services.add_to_history')" placement="top">
                        <el-button type="primary" size="mini" :disabled="!userCan('create service history')" @click="showAddHistoryModal">
                            <fa-icon :icon="['far', 'plus']" fixed-width />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('services.export_to_pdf')" placement="top">
                        <el-button type="primary" size="mini" :disabled="historyData.length == 0" @click="toPdf(false)">
                            <fa-icon :icon="['fas', 'file-pdf']" fixed-width />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('services.print')" placement="top">
                        <el-button type="primary" size="mini" :disabled="historyData.length == 0" class="mr-3" @click="toPdf(true)">
                            <fa-icon :icon="['fas', 'print']" fixed-width />
                        </el-button>
                    </el-tooltip>
                    <DateRangePicker @change="getAll" />
                </div>
            </div>

            <hr class="my-4">

            <HistoryTable :historyData="historyData" @handleDelete="handleDelete" @handleEdit="handleEdit" @handleRatingChange="handleRatingChange" />

            <InitialLoader v-if="$wait.is('loading.initial_*')" />

            <NoDataInformation :data="noDataInformation" waitKey="loading" />
        </div>
    </div>
</template>
<script>
import queryString from 'query-string';

export default {
    components: {
        Summary:          () => import(/* webpackChunkName: "ClientsSummary" */ './components/Summary'),
        HistoryTable:     () => import(/* webpackChunkName: "ClientsHistoryTable" */ './components/ClientsHistoryTable'),
        ClientsDropdown:  () => import(/* webpackChunkName: "ClientsDropdown" */ '@/components/ClientsDropdown'),
        DateRangePicker:  () => import(/* webpackChunkName: "DateRangePicker" */ '@/components/DateRangePicker'),
        ModalEditHistory: () => import(/* webpackChunkName: "ClientsHistory/ModalEditHistory" */ '@/views/services/components/ModalEditHistory'),
        ModalAddHistory:  () => import(/* webpackChunkName: "ClientsHistory/ModalAddHistory" */ '@/views/services/components/ModalAddHistory'),
    },

    data() {
        return {
            noDataInformation:       false,
            historyData:             [],
            selectedHistory:         {},
            modalEditHistoryVisible: false,
            modalEditHistory:        false,
            modalAddHistoryVisible:  false,
            modalAddHistory:         false,
        };
    },

    beforeCreate() {
        this.$wait.start('loading.initial_history');
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('services.service_history'));
        this.$store.commit('setActiveMenuItem', '1-4');
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading');
            const query = queryString.parse(window.location.search, { sort: false });

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/service_history`, {
                params: {
                    client: query.client,
                    from:   query.from ? query.from : this.$dayjs().startOf('month').unix(),
                    to:     query.to ? query.to : this.$dayjs().endOf('month').unix(),
                },
            });

            this.historyData = Object.freeze(data);
            this.noDataInformation = data.length;
            this.$wait.end('loading');
            this.$wait.end('loading_date_range.prev');
            this.$wait.end('loading_date_range.next');
            this.$wait.end('loading.initial_history');
        },

        async toPdf(toPrint = false) {
            const query = queryString.parse(window.location.search, { sort: false });

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/service_history/service_history_pdf_sign_route`, {
                params: {
                    client: query.client,
                    from:   query.from ? query.from : this.$dayjs().startOf('month').unix(),
                    to:     query.to ? query.to : this.$dayjs().endOf('month').unix(),
                    print:  toPrint,
                },
            });

            window.open(data);
        },

        handleEdit(history) {
            this.selectedHistory = history;
            this.modalEditHistoryVisible = true;
            this.modalEditHistory = true;
        },

        handleDelete(uuid) {
            this.$store.commit('showConfirmationModal', {
                actionUrl:  `${process.env.VUE_APP_CORE_API_URL}/services/service_history/${uuid}`,
                actionType: 'destroy',
            });
        },

        async handleRatingChange({ id, rate }) {
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/services/service_history/update_rating`, {
                id,
                rate,
            });
            
            this.$notify.success({ title: this.$t('common.success') });
            this.getAll();
        },

        async showAddHistoryModal() {
            this.modalAddHistoryVisible = true;
            await new Promise(resolve => setTimeout(resolve, 100));
            this.modalAddHistory = true;
        },
    },
};
</script>
<style scoped>

.menu .el-button--mini {
    padding: 7px !important;
}

.main-card {
    min-height: calc(100vh - 200px);
}

</style>
